import React, { useState, useEffect, useRef } from "react";
import {
  TabGroup, Tab, Button, Select, SelectOption
} from "@hdir/ui";
import { faSliders } from "@fortawesome/pro-light-svg-icons";

import CardGeneral from "../../../react4xp/shared/Cards/General/General";
import CardNormerendeProdukter from "../../../react4xp/shared/Cards/NormerendeProdukter/NormerendeProdukter";
import CardTilskudd from "../../../react4xp/shared/Cards/Tilskudd/Tilskudd";
import FontAwesomeIconWrapper from "../../../react4xp/shared/FontAwesomeIconWrapper/FontAwesomeIconWrapper";
import Filter from "../../../react4xp/shared/Filter/Filter";
import ListSearchResultContainer from "../../../react4xp/shared/ListSearchResultContainer/ListSearchResultContainer";
import ListSearchSearchComponent from "../../../react4xp/shared/ListSearchSearchComponent/ListSearchSearchComponent";

import {
  CheckedFilter,
  FetchDataOptions,
  ListSearchProps,
  QueryObject,
  SetTabsOptions,
  TabLists
} from "./type.d";

import "./listSearch.scss";

// Mapping for cards
const cardComponents = {
  cardTilskudd: CardTilskudd,
  cardNormerendeProdukter: CardNormerendeProdukter,
  cardGeneral: CardGeneral
};

function ListSearch(props: Readonly<ListSearchProps>) {
  const {
    filterCloseable,
    filterFromParams,
    filterLists,
    groupByField,
    hideSearchField,
    isEditMode,
    localizedForListSearch,
    maxCardsToDisplay,
    paginationSize,
    queryObject,
    searchQueryFromParams,
    selectedListCardView,
    serviceUrl,
    sortByOptions,
    tabsData,
    title,
    useTabs,
    useNormerendeCollection
  } : ListSearchProps = props;

  // Set intital filter values. Add values if selected from params
  let initialFilterValues: CheckedFilter[] = [];
  if (filterFromParams) {
    initialFilterValues = filterLists.map((filterObj) => {
      const values: string[] = [];
      filterObj.filterList.forEach((filterValue) => {
        if (filterValue.isCheckedInitially) {
          values.push(filterValue.value);
        }
      });

      return {
        field: filterObj.field,
        values: values
      };
    });
  } else {
    initialFilterValues = filterLists.map((filterObj) => ({
      field: filterObj.field,
      values: []
    }));
  }

  const [isLoading, setIsLoading] = useState(true);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [list, setList] = useState([]);
  const [currentTotalCount, setCurrentTotalCount] = useState(0);
  const [currentQueryObject, setCurrentQueryObject] = useState(queryObject);

  const [tabsLists, setTabsLists] = useState<TabLists[]>([]);

  // Keeps track of checked values in the filter
  const [currentFilter, setCurrentFilter] = useState(initialFilterValues);
  const [isFilterOpen, setIsFilterOpen] = useState(filterFromParams?.length > 0);
  const [countChecked, setCountChecked] = useState(filterFromParams?.length || 0);

  async function fetchData(queryObject : QueryObject, options: FetchDataOptions = {}) {
    const { searchTerm, filterQuery, sortByScore } = options;

    try {
      let serviceUrlWithParams = `${serviceUrl}?`;

      if (queryObject.count) {
        serviceUrlWithParams += `&count=${queryObject.count}`;
      }

      if (sortByScore || queryObject.sort) {
        // Use sortByScore as argument if present. sortByScore is added when query is iniated from searchterm input in frontend
        serviceUrlWithParams += sortByScore ? `&sort=${sortByScore}` : `&sort=${queryObject.sort}`;
      }

      if (queryObject.query) {
        const queryPathsString = queryObject.query.map((query :string) => `${query}`).join(" OR ");
        serviceUrlWithParams += `&query=${queryPathsString}`;
      }

      if (queryObject.tabQuery) {
        serviceUrlWithParams += `&tabQuery=${queryObject.tabQuery}`;
      }

      if (queryObject.contentTypes) {
        const contentTypesString = queryObject.contentTypes.join(",");
        serviceUrlWithParams += `&contentTypes=${contentTypesString}`;
      }

      if (searchTerm) {
        serviceUrlWithParams += `&searchTerm=${searchTerm}`;
      }

      if (filterQuery) {
        serviceUrlWithParams += `&filter=${filterQuery}`;
      }

      if (groupByField) {
        serviceUrlWithParams += `&groupBy=${groupByField}`;
      }

      if (useNormerendeCollection) {
        serviceUrlWithParams += "&useNormerendeCollection=true";
      }

      const response = await fetch(serviceUrlWithParams);
      const data = await response.json();
      setIsLoading(false);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }

  const getFilterQuery = () : string => {
    const filterQueryArray : string[] = [];
    currentFilter.forEach((filterObj :CheckedFilter) => {
      // Filter is selected
      if (filterObj.values?.length > 0) {
        // Add all values in field to filter query. Value within a field is joined with "OR". Example: (<field> = '<value>' OR <field> = '<value1>')
        const filterFieldQuery = filterObj.values.map((value) => `${filterObj.field} = '${value}'`).join(" OR ");
        filterQueryArray.push(`(${filterFieldQuery})`);
      }
    });

    if (filterQueryArray.length === 1) {
      return filterQueryArray[0];
    }

    // Join multiple fields with 'AND'. Example (<field> = <value> OR <field> = <value1>') AND (<field> = '<value>')
    if (filterQueryArray.length > 1) {
      return filterQueryArray.join(" AND ");
    }

    return "";
  };

  // Helper for setting and updating data in tabs
  const setTabsListsHelper = (listId: string | undefined | number, options: SetTabsOptions = {}) => {
    const { newList, newTotalCount, newQueryObject } = options;

    setTabsLists((prevTabsLists) => {
      const newTabsLists = prevTabsLists.map((tab) => {
        if (listId === tab.tabId) {
          return {
            ...tab,
            list: newList ?? tab.list,
            tabQuery: newQueryObject ?? tab.tabQuery,
            totalCount: newTotalCount ?? tab.totalCount
          };
        }
        return tab;
      });
      return newTabsLists;
    });
  };

  // Helper for sorting results by score and not default sort from config
  const sortByScore = (searchQuery : string) => {
    let sortByScoreString = "";
    if (searchQuery !== "") {
      sortByScoreString = "_score DESC";
    }

    return sortByScoreString;
  };

  const fetchListOnSearchInput = async(searchQuery :string) => {
    if (tabsLists.length > 0) {
      const promises = tabsLists.map((tab) => fetchData(tab.tabQuery, { searchTerm: searchQuery, filterQuery: filterFromParams ? getFilterQuery() : [], sortByScore: sortByScore(searchQuery) }));
      const data = await Promise.all(promises);
      data.map((data, index) => setTabsListsHelper(index, { newList: data.list, newTotalCount: data.totalCount }));
    } else {
      const data = await fetchData(currentQueryObject, { searchTerm: searchQuery, filterQuery: filterFromParams ? getFilterQuery() : [], sortByScore: sortByScore(searchQuery) });
      setList(data?.list);
      setCurrentTotalCount(data?.totalCount);
    }
  };

  const fetchInitialList = async() => {
    // Fetch data with own query for each tab. Filter from params is used if set.
    if (useTabs && tabsData?.length > 0) {
      const promises = tabsData.map((tabData) => fetchData(tabData.tabQuery, { filterQuery: filterFromParams ? getFilterQuery() : "" }));
      const data = await Promise.all(promises);
      const initTabsList = tabsData.map((tab, index) => ({
        tabId: tab.tabId,
        title: tab.title,
        list: data[index]?.list,
        tabQuery: tab.tabQuery,
        totalCount: data[index]?.totalCount
      }));

      setTabsLists(initTabsList);
    } else {
      // Filter from params used in initial search if set
      const results = await fetchData(queryObject, { filterQuery: filterFromParams ? getFilterQuery() : "" });
      setList(results?.list);
      setCurrentTotalCount(results?.totalCount);
    }
  };

  // Fetch and set list(s) on load
  useEffect(() => {
  // Do intital search with query from params
    if (searchQueryFromParams) {
      fetchListOnSearchInput(searchQueryFromParams);
      setSearchQuery(searchQueryFromParams);
      if (searchInputRef.current) {
        searchInputRef.current.value = searchQueryFromParams;
      }
    } else {
      fetchInitialList();
    }
  }, []);

  const fetchWithFilter = async(emptyFilter?: boolean): Promise<void> => {
    const filterQuery = emptyFilter ? "" : getFilterQuery();
    if (tabsLists.length > 0) {
      const promises = tabsLists.map((tab) => fetchData(tab.tabQuery, { searchTerm: searchQuery, filterQuery: filterQuery }));
      const data = await Promise.all(promises);
      data.map((data, index) => setTabsListsHelper(index, { newList: data.list, newTotalCount: data.totalCount }));
    } else {
      const data = await fetchData(currentQueryObject, { searchTerm: searchQuery, filterQuery: filterQuery });
      setList(data?.list);
      setCurrentTotalCount(data?.totalCount);
    }
  };

  const resetDisplayedFilter = (): void => {
    const emptyFilter = currentFilter.map((filterObj) => ({
      ...filterObj,
      values: []
    }));
    setCurrentFilter(emptyFilter);
  };

  const resetFilter = (): void => {
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    resetDisplayedFilter();
    fetchWithFilter(true);
  };

  const handleFilterSelected = (checkedValues: string[], field: string): void => {
    setCurrentFilter((prevFilter) => prevFilter.map((filterObj) => {
      if (filterObj.field === field) {
        return { ...filterObj, values: checkedValues };
      }

      return filterObj;
    }));
  };

  async function handleFetchMore(id? : string) {
    if (useTabs && id !== "") {
      let _currentQueryObject = {};
      tabsLists.forEach((tab) => {
        if (tab.tabId === id) {
          _currentQueryObject = {
            ...tab?.tabQuery,
            // Count is the lowest value of current list + paginationsSize or maxCardsToDisplay.
            count: Math.min(((tab?.list?.length ?? 0) + paginationSize), maxCardsToDisplay)
          };
        }
      });
      const data = await fetchData(_currentQueryObject, { searchTerm: searchQuery, filterQuery: getFilterQuery(), sortByScore: sortByScore(searchQuery) });
      setTabsListsHelper(id, { newList: data.list, newTotalCount: data.totalCount, newQueryObject: _currentQueryObject });
    } else {
      const _currentQueryObject = { ...currentQueryObject };
      // Count is the lowest value of current list + paginationsSize or maxCardsToDisplay.
      _currentQueryObject.count = Math.min(((list?.length ?? 0) + paginationSize), maxCardsToDisplay);
      setCurrentQueryObject(_currentQueryObject);
      const data = await fetchData(_currentQueryObject, { searchTerm: searchQuery, filterQuery: getFilterQuery(), sortByScore: sortByScore(searchQuery) });
      setList(data?.list);

      setCurrentTotalCount(data?.totalCount);
    }
  }

  async function handleSortSelected(sortByValue : string) {
    if (tabsLists.length > 0) {
      tabsLists.forEach(async(tab) => {
        const _currentQueryObject = tab.tabQuery;
        _currentQueryObject.sort = sortByValue;
        const promises = tabsLists.map(() => fetchData(_currentQueryObject, { searchTerm: searchQuery, filterQuery: getFilterQuery() }));
        const data = await Promise.all(promises);
        data.map((data) => setTabsListsHelper(tab.tabId, { newList: data.list, newTotalCount: data.totalCount }));
      });
    } else {
      const _currentQueryObject = currentQueryObject;
      _currentQueryObject.sort = sortByValue;
      const data = await fetchData(_currentQueryObject, { searchTerm: searchQuery, filterQuery: getFilterQuery() });
      setList(data?.list);
      setCurrentTotalCount(data?.totalCount);
      setCurrentQueryObject(_currentQueryObject);
    }
  }

  // Get card component based on content type / mapping in controller
  const SelectedCardComponent = cardComponents[selectedListCardView];

  return (
    <div className="w-full py-md tablet:py-lg desktop:py-xl">
      { !hideSearchField && (<ListSearchSearchComponent searchInputRef={searchInputRef} fetchListOnSearchInput={fetchListOnSearchInput} setSearchQuery={setSearchQuery} localizedForListSearch={localizedForListSearch} title={title} />)}
      { hideSearchField && (<h2 className="text-lg">{title}</h2>) }
      <div className="flex flex-col mt-md tablet:mt-xl">
        { (filterLists.length > 0 && !filterCloseable) && (
          <div className="mb-lg">
            <Filter
              countChecked={countChecked}
              currentFilter={currentFilter}
              filterCloseable={filterCloseable}
              filterLists={filterLists}
              handleCheckboxChange={handleFilterSelected}
              isEditMode={isEditMode}
              isFilterOpen={isFilterOpen}
              localizedForListSearch={localizedForListSearch}
              onFetchWithFilter={fetchWithFilter}
              onResetFilter={resetFilter}
              setCountChecked={setCountChecked}
              setIsFilterOpen={setIsFilterOpen}
            />
          </div>
        )}

        { /* Helsecore slår ihjel hdir styling med flex-col */ }
        <div className={`flex nt-flex-col tablet:flex-row ${useTabs ? "justify-end" : "justify-between"} items-center gap-md`}>
          <span aria-live="polite" aria-atomic="true" aria-relevant="all" className="sr-only">
            {`${currentTotalCount} treff funnet for ${searchInputRef?.current?.value}. Viser ${list?.length} treff`}
          </span>
          { !useTabs
            && <span aria-hidden="true" className="self-start">{`Viser ${list?.length || 0} av ${currentTotalCount || 0} treff`}</span>}
          { sortByOptions.length > 1 && (
            <Select
              className="sortSelector flex-row self-end"
              defaultValue={sortByOptions[0]}
              label={localizedForListSearch?.sortBy || "Sorter etter:"}
              getDisplayName={(option: { name: string; field: (string | undefined) }): string => option.name}
              onChange={(e) => {
                handleSortSelected(e?.field ?? "");
              }}
              fullWidth={false}
              name="selector"
            >
              { sortByOptions.map((option, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SelectOption key={`${option.name}-${index}`} value={option}>
                  {option.name}
                </SelectOption>
              ))}
            </Select>
          )}

          { (filterLists.length > 0 && filterCloseable) && (
            <Button onClick={() => setIsFilterOpen(!isFilterOpen)} className="w-32 self-end">
              <FontAwesomeIconWrapper icon={faSliders} />
              {`${localizedForListSearch?.filter ?? "Filtrer"} ${countChecked > 0 ? `(${countChecked})` : ""}`}
            </Button>
          )}
        </div>

        { (filterLists.length > 0 && filterCloseable) && (
          <Filter
            countChecked={countChecked}
            currentFilter={currentFilter}
            filterCloseable={filterCloseable}
            filterLists={filterLists}
            handleCheckboxChange={handleFilterSelected}
            isEditMode={isEditMode}
            isFilterOpen={isFilterOpen}
            localizedForListSearch={localizedForListSearch}
            onFetchWithFilter={fetchWithFilter}
            onResetFilter={resetFilter}
            setCountChecked={setCountChecked}
            setIsFilterOpen={setIsFilterOpen}
          />
        )}
      </div>

      {(useTabs && tabsLists?.length > 0) ? (
        <TabGroup className="mt-md">
          {tabsLists?.map((tab) => (
            <Tab tabLabel={`${tab?.title} (${tab?.list?.length})`} key={tab.tabId}>
              <ListSearchResultContainer
                paginationSize={paginationSize}
                isLoading={isLoading}
                SelectedCardComponent={SelectedCardComponent}
                list={tab?.list}
                localizedForListSearch={localizedForListSearch}
              />

              {(tab?.list?.length !== 0 && tab?.totalCount > tab?.list?.length && maxCardsToDisplay > tab?.list?.length) && (
                <div className="flex justify-center mt-lg">
                  <Button
                    size="medium"
                    variant="secondary"
                    onClick={() => handleFetchMore(tab.tabId)}
                  >
                    {localizedForListSearch?.showMore ?? "Vis flere"}
                  </Button>
                </div>
              )}
            </Tab>
          ))}
        </TabGroup>
      ) : (
        <>
          <ListSearchResultContainer
            paginationSize={paginationSize}
            isLoading={isLoading}
            SelectedCardComponent={SelectedCardComponent}
            list={list}
            localizedForListSearch={localizedForListSearch}
          />
          {(currentTotalCount > list?.length && maxCardsToDisplay > list?.length) && (
            <div className="flex justify-center mt-lg">
              <Button
                size="medium"
                variant="secondary"
                onClick={() => handleFetchMore()}
              >
                {localizedForListSearch?.showMore ?? "Vis flere"}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default function(props: Readonly<ListSearchProps>) {
  return <ListSearch {...props} />;
}
